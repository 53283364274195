<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>采购价管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="add(null, false)">新增采购价</el-button>
        </el-col>
      </el-row>
      <!-- 订单列表数据 -->
      <el-table :data="list" style="width: 100%">
        
        <el-table-column prop="goodsName" label="商品名称" >
          <template slot-scope="scope">
            {{getGoodsById(scope.row.goodsId).title}}
          </template>
        </el-table-column>
        <el-table-column prop="money" label="采购价" width="120">
        </el-table-column>
        <el-table-column prop="beginDate" label="生效时间" width="120">
        </el-table-column>
        <el-table-column prop="endDate" label="失效时间" width="120">
        </el-table-column>
        <el-table-column label="操作" width="130">
          <template slot-scope="scope">
            <el-button type="primary" @click="add(scope.row, true)">编辑</el-button>
            <el-button type="danger" @click="_delete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>


    <el-dialog title="提示" :visible.sync="dialogVisible" width="50%" >
      <!-- 主要内容 -->
      <el-form label-width="100px">
        
        <el-form-item label="商品" >
          <el-select v-model="goodsId" placeholder="商品" class="search-select" >
            <el-option v-for="item in goodsList" :key="item.goodsId" :label="item.title" :value="item.goodsId"/>
          </el-select>
        </el-form-item>
        <el-form-item label="采购价" >
          <el-input v-model="money"></el-input>
        </el-form-item>
        <el-form-item label="生效时间" >
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="beginDate"
            placeholder="选择时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="失效时间" >
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="endDate"
            placeholder="选择时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
//
<script>
export default {

  data () {
    return {
      list: [],
      dialogVisible: false,
      goodsId: '',
      money: '',
      beginDate: '',
      endDate: '',
      editId: null,
      params: {
        beginDate: '',
        endDate: '',
        goodsId: '',
        currentPage: 1,
        pageSize: 50
      },
      goodsList: []
    }
  },
  created () {
    this.GetList();
    this.$api.GetGoodsListToPage({currentPage: 1, pageSize: 200,}).then((res) => {
        this.goodsList = res.data.records
    })
  },
  methods: {
    GetList () {
      this.$api.GetPurchaseMoneyList(this.params).then((res) => {
        if (res.code !== 200) this.$message.error('请求失败')
        this.list = res.data.records
      })
    },
    getGoodsById(id){
      return this.goodsList.find(o => o.goodsId == id) || {}
    },

    _delete(id){
      this.$confirm('确定删除吗？', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.DeletePurchaseMoney({id: id}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.$message.success('删除成功')
              this.GetList();
            })
          }
        }
      })
    },
    // 点击显示出编辑商品对话框
    add (row, isEdit) {
      this.editId = null;
      this.goodsId = '';
      this.money = '';
      this.beginDate = '';
      this.endDate = '';

      if(isEdit){
        this.editId = row.id;
        this.goodsId = row.goodsId;
        this.money = row.money;
        this.beginDate = row.beginDate;
        this.endDate = row.endDate;
      }
      this.dialogVisible = true
    },

    // 编辑商品
    save () {
      if(!this.goodsId) return this.$message.error('请选择商品');
      if(!this.money) return this.$message.error('请输入采购价');
      if(!this.beginDate) return this.$message.error('请选择生效时间');
      if(!this.endDate) return this.$message.error('请选择失效时间');
      if(new Date(this.endDate).getTime() < new Date(this.beginDate).getTime()){
        alert('结束时间不能小于开始时间')
        return
      }
      let promise = null;
      const params = {
        goodsId: this.goodsId,
        money: this.money,
        beginDate: this.beginDate,
        endDate: this.endDate,
      }
      if(this.editId){
        params.id = this.editId;
        promise = this.$api.UpdatePurchaseMoney(params)
      }else{
        promise = this.$api.AddPurchaseMoney(params)
      }
      promise.then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求失败')
        }
        this.$message.success('保存成功')
        this.dialogVisible = false;
        this.GetList();
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.el-button {
  padding: 10px;
}

.search-input {
  width:150px;
    margin-right: 5px;
}
.search-select{
  width:140px;
  margin-right: 5px;
}
.search-date{
  width:135px;
}
</style>
